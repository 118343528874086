import React, {useState, useEffect} from 'react';
import Column from "@amzn/meridian/column";
import { css } from "emotion";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import Tooltip from "@amzn/meridian/tooltip"
import Card from "@amzn/meridian/card";
import {getStowMetrics, exitModule, getNumericalDisplayValueFromData} from "./NetworkUtils";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {AnalyticEventKeys, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import { ANALYTICS_DESCRIPTION } from "./Constants";

const StowSummaryView = (props) => {

    const [data, setData] = useState(null);

    const centerAlignText = css({
        textAlign: "center"
    });

    const linkText = css({
        padding: "0px",
        color: "#077398",
        textDecoration: "underline",
        textDecorationColor: "#077398"
    });

    const noRightMarginText = css({
        marginRight: "0px !important"
    })

    const sessionSummaryTimingText = css({
        textAlign: "right",
        color: "grey"
    })

    useEffect(() => {
        const START_TIME = Date.now();
        getStowMetrics(setData);

        function recordMetric() {
            MobileAnalyticsHelper.processAnalytics(START_TIME, ANALYTICS_DESCRIPTION.STOW_SUMMARY_METRIC,
                null, AnalyticEventKeys.Modules.PACKAGE_STOW, null, null,
                AnalyticEventKeys.Events.USER_VIEWED_STOW_SUMMARY_INSIGHT, false, null, null);
        }

        return () => {
            recordMetric();
        }
    }, []);

    const renderStowPPH = () => {
        return (
            <Column>
                <Row widths={["grid-12"]} alignmentHorizontal={"center"} width={"100%"} id={"info-icon-row"}>
                    <Box>
                        <Text className={centerAlignText} type={"h300"}>{`Packages per hour`}</Text>
                    </Box>
                </Row>
                <Row widths={["grid-6", "grid-6"]} alignmentHorizontal={"end"} width={"100%"} id={"info-icon-row"}>
                    <Text className={noRightMarginText}>{`You: ${getNumericalDisplayValueFromData(data.associateStowMetrics.pph)}`}</Text>
                    <Text>{`Station avg: ${getNumericalDisplayValueFromData(data.stationStowMetrics.pph)}`}</Text>
                </Row>
                <Row alignmentHorizontal={"end"} width={"100%"}>
                    <Tooltip position={"start"} title={"The number of packages stowed per hour by you vs the station average"}>
                        <Text type={"b200"} className={linkText}>{`What does this mean?`}</Text>
                    </Tooltip>
                </Row>
            </Column>
        )
    }

    const renderPackagesStowed = () => {
        return (
            <Column>
                <Row widths={["grid-12"]} alignmentHorizontal={"center"} width={"100%"} id={"info-icon-row"}>
                    <Box>
                        <Text className={centerAlignText} type={"h300"}>{`Total packages stowed`}</Text>
                    </Box>
                </Row>
                <Row widths={["grid-6", "grid-6"]} alignmentHorizontal={"end"} width={"100%"} id={"info-icon-row"}>
                    <Text className={noRightMarginText}>{`You: ${getNumericalDisplayValueFromData(data.associateStowMetrics.stowedCount)}`}</Text>
                    <Text>{`Station avg: ${getNumericalDisplayValueFromData(data.stationStowMetrics.stowedCount)}`}</Text>
                </Row>
                <Row alignmentHorizontal={"end"} width={"100%"}>
                    <Tooltip position={"start"} title={"Total number of packages stowed by you vs the station average"}>
                        <Text type={"b200"} className={linkText}>{`What does this mean?`}</Text>
                    </Tooltip>
                </Row>
            </Column>
        )
    }

    const renderFsaf = () => {
        return (
            <Column>
                <Row widths={["grid-12"]} alignmentHorizontal={"center"} width={"100%"} id={"info-icon-row"}>
                    <Box>
                        <Text className={centerAlignText} type={"h300"}>{`FSAF`}</Text>
                    </Box>
                </Row>
                <Row widths={["grid-6", "grid-6"]} alignmentHorizontal={"end"} width={"100%"} id={"info-icon-row"}>
                    <Text className={noRightMarginText}>{`You: ${getNumericalDisplayValueFromData(data.associateStowMetrics.fsaf)}`}</Text>
                    <Text>{`Station avg: ${getNumericalDisplayValueFromData(data.stationStowMetrics.fsaf)}`}</Text>
                </Row>
                <Row alignmentHorizontal={"end"} width={"100%"}>
                    <Tooltip position={"start"} title={"Fist scan attempt failure for you vs the station average"}>
                        <Text type={"b200"} className={linkText}>{`What does this mean?`}</Text>
                    </Tooltip>
                </Row>
            </Column>
        )
    }

    const renderSessionSummaryTimingText = () => {
        const now = new Date();
        const timeText = now.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        return <Text type={"b200"} className={sessionSummaryTimingText}>{`Your shift summary till ${timeText}`}</Text>;
    }

    const renderStowMetrics = () => {

        // Display nothing until data is not fetched.
        if (!data) {
            return <Loader/>;
        }

        // If the data is not fetched in correct format, then exit the module.
        if (!data.associateStowMetrics || !data.stationStowMetrics) {
            exitModule()
        }

        return (
            <Column width={"100%"} height={"100%"} spacingInset={"small"} alignmentVertical={"center"}>
                {renderSessionSummaryTimingText()}
                <Card width={"100%"} spacingInset={"200"}>
                    {renderStowPPH()}
                </Card>
                <Card width={"100%"} spacingInset={"200"}>
                    {renderPackagesStowed()}
                </Card>
                <Card width={"100%"} spacingInset={"200"}>
                    {renderFsaf()}
                </Card>
            </Column>
        )
    }

    return (
        renderStowMetrics()
    )
}

export default StowSummaryView;