export const ROUTING = {
    LANDING: "/",
    NO_INTERNET: "/no-internet",
    CREATE_NOTIFICATION: "/create-notification",
    LIST_NOTIFICATION: "/list-notification",
    NEW: "/new",
    EDIT_ALIAS_BADGE_SCAN: "/edit-alias-badge-scan",
    EDIT_ALIAS_TEXT_INPUT: "/edit-alias-text-input",
    EDIT_LOCATION: "/edit-location",
    INSIGHT: "/insight",
    SUMMARY: "/summary"
}

export const getNotificationRoutingPath = (path) => {
    return ROUTING.CREATE_NOTIFICATION + path;
}

export const getInsightRoutingPath = (path) => {
    return ROUTING.INSIGHT + path;
}